
import { defineComponent } from "vue";
import Moment from "moment";

export default defineComponent({
  name: "activity",
  props: ["phone"],
  components: {},
  methods: {
    getHumanDate(date: string) {
      return Moment(date, "YYYY-MM-DD hh:mm:ss").format("DD-MM-YYYY hh:mm");
    },
  },
});
