import { Nullable } from "element-plus/es/utils/types";

export const currencyFormat = (number: any, decimal: null | number = null) => {
  let roundedNumber = null;
  let parts = null;
  if (typeof number !== "number") {
    number = parseFloat(number);
  }

  roundedNumber = Math.round(number * 100) / 100; // Round to two decimal places
  roundedNumber = parseFloat(roundedNumber.toFixed(decimal ? decimal : 0)); // Convert to string and round to two decimal places
  parts = roundedNumber.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousands

  return `RM ${parts.join(".")}`;
}
