
import { defineComponent } from "vue";
import moment from "moment";
import { Form, Field, ErrorMessage } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import { currencyFormat } from "@/core/helpers/currencyFormatter";
import Moment from "moment";
import * as Yup from "yup";
import AdvisoryDetails from "./AdvisoryDetails.vue";
import AdvisoryHistory from "./AdvisoryHistory.vue";
import CallDetails from "./CallDetails.vue";
import AdvisoryNotes from "./AdvisoryNotes.vue";

export default defineComponent({
  name: "activity",
  components: {
    ErrorMessage,
    Form,
    Field,
    ElCurrencyInput,
    AdvisoryDetails,
    AdvisoryHistory,
    CallDetails,
    AdvisoryNotes,
  },
  setup() {
    return {
      currencyFormat,
    };
  },
  data() {
    const route = useRoute();
    const router = useRouter();
    const cstmr_id = route.params.id ?? null;
    const appointment_id = route.params.appointment_id ?? null;
    // const phone.method_id = null;
    const appointment = {} as any;
    const store = useStore();

    const roles = store.getters?.currentUser?.roles?.map((a) => a.name);
    const isAdmin = roles?.includes("admin");
    const isViewer = roles?.includes("viewer");
    const isFA = roles?.includes("financial-advisor");
    const isBranchHead = roles?.includes("branch-head");
    const isVCLinkReady = false;

    const updatePhoneFormSchema = Yup.object({
      phone: Yup.object({
        method_id: Yup.string().required("This field is required"),
        result: Yup.string().when("method_id", {
          is: "1",
          then: Yup.string().required("This field is required"),
          otherwise: Yup.string(),
        }),
        remarks: Yup.string().when("method_id", {
          is: "1",
          then: Yup.string().required("This field is required"),
          otherwise: Yup.string(),
        }),
        reason: Yup.string().when("remarks", {
          is: "Advisory",
          then: Yup.string().required("This field is required"),
          otherwise: Yup.string(),
        }),
        // reason: Yup.string(),
      }),
    });

    const updateAdvisoryFormSchema = Yup.object({
      appointmentSession: Yup.object({
        source_type_id: Yup.number()
          .required("This field is required")
          .typeError("This field is required"),
        advisory: Yup.string().required("This field is required"),
        latest_service: Yup.number()
          .required("This field is required")
          .typeError("This field is required"),
        agree_to_join_dmp: Yup.string().when("latest_service", {
          is: 6,
          then: Yup.string()
            .required("This field is required")
            .typeError("This field is required"),
          otherwise: Yup.string(),
        }),
        agree_to_join_sdrs: Yup.string().when("latest_service", {
          is: 25,
          then: Yup.string()
            .required("This field is required")
            .typeError("This field is required"),
          otherwise: Yup.string(),
        }),
        post_dmp_enquiry: Yup.string().when("latest_service", {
          is: 24,
          then: Yup.string()
            .required("This field is required")
            .typeError("This field is required"),
          otherwise: Yup.string(),
        }),
        credit_rating_provider: Yup.string().when("latest_service", {
          is: 33,
          then: Yup.string()
            .required("This field is required")
            .typeError("This field is required"),
          otherwise: Yup.string(),
        }),
        account_enquiry_type: Yup.string().when("latest_service", {
          is: 32,
          then: Yup.string()
            .required("This field is required")
            .typeError("This field is required"),
          otherwise: Yup.string(),
        }),
        remark: Yup.string().when("post_dmp_enquiry", {
          is: "Reschedule",
          then: Yup.string()
            .required("This field is required")
            .typeError("This field is required"),
          otherwise: Yup.string(),
        }),
      }),
      sme: Yup.object({
        latest_service: Yup.number(),
        type_of_business: Yup.string().when("latest_service", {
          is: (latest_service) => {
            if (latest_service == 25 || latest_service == 26) return true;
            else return false;
          },
          then: Yup.string().required("This field is required"),
          otherwise: Yup.string(),
        }),
        type_of_lenders: Yup.string().when("latest_service", {
          is: (latest_service) => {
            if (latest_service == 25 || latest_service == 26) return true;
            else return false;
          },
          then: Yup.string().required("This field is required"),
          otherwise: Yup.string(),
        }),
        other_advisory: Yup.string().when("latest_service", {
          is: (latest_service) => {
            if (latest_service == 25) return true;
            else return false;
          },
          then: Yup.string().required("This field is required"),
          otherwise: Yup.string(),
        }),
        financial_skill: Yup.string().when("latest_service", {
          is: (latest_service) => {
            if (latest_service == 4) return true;
            else return false;
          },
          then: Yup.string().required("This field is required"),
          otherwise: Yup.string(),
        }),
      }),
      hh: Yup.object({
        latest_service: Yup.number(),
        financial_skill: Yup.string().when("latest_service", {
          is: (latest_service) => {
            if (latest_service == 3) return true;
            else return false;
          },
          then: Yup.string().required("This field is required"),
          otherwise: Yup.string(),
        }),
      }),
    });

    const appointmentSession = {
      source_type_id: null,
      type_of_enquiries: "",
      ge_categories: "",
      type_of_customer: "",
      type_of_enquiry: "",
      // post_dmp_enquiry: "",
      sub_categories: "",
      type_of_financial_skills_hh: "",
      type_of_financial_skills_bus: "",
      type_of_financial_resolution_hh: "Advisory on DMP",
      type_of_financial_resolution_bus: "",
      additional_advisory: "",
      type_of_bus: "",
      type_of_lenders: "",
      business_categories: "",
      advisor_note: "",
      //New
      advisory: "",
      service: "",
      latest_service: "",
      post_dmp_enquiry: "",
      remark: "",
      account_enquiry_type: "",
      other_account_enquiry_type: "",
      referal_program: {
        social_synergy: false,
      },
      //DMP
      agree_to_join_dmp: "",
      agree_to_join_sdrs: "",
      credit_rating_provider: "",
    };

    const phone = {
      method_id: null,
      result: "",
      remarks: "",
      reason: "",
      advisor_note: "",
    };

    const sme = {
      latest_service: "",
      type_of_business: "",
      type_of_lenders: "",
      other_advisory: "",
      financial_skill: "",
    };

    const hh = {
      latest_service: "",
      financial_skill: "",
    };

    const typeOfSourceOptions = [
      // {
      //   value: "1",
      //   label: "Appointment",
      // },
      // {
      //   value: "2",
      //   label: "GE Form/Survey Monkey",
      // },
      // {
      //   value: "3",
      //   label: "QR Code/Walk In",
      // },
      {
        value: "4",
        label: "FI Linkage",
      },
      {
        value: "5",
        label: "PARAS",
      },
      {
        value: "6",
        label: "2CP",
      },
      {
        value: "7",
        label: "Excess Payment",
      },
      {
        value: "8",
        label: "Collaboration",
      },
      {
        value: "9",
        label: "Customer Call branch",
      },
    ];

    const advisories = [
      {
        value: "Advisory on DMP",
        label: "Advisory on DMP",
      },
      {
        value: "Advisory for SME",
        label: "Advisory for SME",
      },
      {
        value: "PFM",
        label: "PFM",
      },
      {
        value: "GE",
        label: "GE",
      },
    ];

    const services = {
      DMP: [
        {
          value: "6",
          label: "Financial Resolution - Household",
        },
      ],
      SME: [
        {
          value: "25",
          label: "Small Debt Resolution Scheme (SDRS)",
        },
        { value: "26", label: "Repayment Assistance" },
        {
          value: "4",
          label: "Financial Skills - Business (SME)",
        },
        {
          value: "31",
          label: "MIDF Second Chance Financing",
        },
      ],
      PFM: [
        {
          value: "16",
          label: "Financial Advisory Services (MyKNP)",
        },
        {
          value: "12",
          label: "Financial Planning - Household",
        },
        {
          value: "14",
          label: "Financial Scoring - Household",
        },
        {
          value: "3",
          label: "Financial Skills - Household",
        },
        {
          value: "15",
          label: "Reverse Mortgage - Skim Saraan Bercagar (SSB)",
        },
        { value: "1", label: "URUS Help" },
        {
          value: "22",
          label: "Personalized Financial Plan",
        },
      ],
      GE: [
        {
          value: "21",
          label: "CCRIS Report/eCCRIS",
        },
        {
          value: "24",
          label: "Post DMP",
        },
        {
          value: "32",
          label: "Account Enquiry",
        },
        {
          value: "33",
          label: "Credit Rating Provider",
        },
      ],
    };

    const post_dmp_enquiries = [
      { value: "Termination", label: "Termination" },
      { value: "Settlement", label: "Settlement" },
      { value: "Withdrawal", label: "Withdrawal" },
      { value: "Reschedule", label: "Reschedule" },
      { value: "Lapsed", label: "Lapsed" },
      { value: "BLD expired", label: "BLD expired" },
      { value: "Cancel Counselling", label: "Cancel Counselling" },
      { value: "DMP Completed", label: "DMP Completed" },
      { value: "Tenure Completed", label: "Tenure Completed" },
      { value: "Postponement", label: "Postponement" },
      { value: "Nok-Voluntary settlement", label: "Nok-Voluntary settlement" },
    ];

    const remarks = [
      { value: "Add on facility", label: "Add on facility" },
      {
        value: "Reduce/Increase Installment",
        label: "Reduce/Increase Installment",
      },
    ];

    const type_of_account_enquiries = [
      { value: "ANGKASA Deduction", label: "ANGKASA Deduction" },
      {
        value: "Harrassment by FI's Debt Collector",
        label: "Harrassment by FI's Debt Collector",
      },
      {
        value: "Penalty / Interest charge by FI",
        label: "Penalty / Interest charge by FI",
      },
      { value: "Payment related issues", label: "Payment related issues" },
      {
        value: "DMP Outstanding balance differ",
        label: "DMP Outstanding balance differ",
      },
      {
        value: "Facility under Direct Payment e.g, Matrix 2",
        label: "Facility under Direct Payment e.g, Matrix 2",
      },
      {
        value: "Other",
        label: "Other",
      },
    ];

    const credit_rating_providers = [
      { value: "CTOS", label: "CTOS" },
      {
        value: "Credit Bureau Malaysia",
        label: "Credit Bureau Malaysia ",
      },
      {
        value: "Experian",
        label: "Experian",
      },
    ];

    const callResult = [
      {
        value: "Contactable",
        label: "Contactable",
      },
      {
        value: "Uncontactable",
        label: "Uncontactable",
      },
    ];

    const actionRemarksUnCon = [
      {
        value: "Number is not reachable",
        label: "Number is not reachable",
      },
      {
        value: "Number is not in service",
        label: "Number is not in service",
      },
    ];

    const actionRemarksCon = [
      {
        value: "Not ready to make decision",
        label: "Not ready to make decision",
      },
      {
        value: "Not Interested",
        label: "Not Interested",
      },
      {
        value: "Want to Self Manage",
        label: "Want to Self Manage",
      },
      {
        value: "Name will be blacklisted",
        label: "Name will be blacklisted",
      },
      {
        value: "Inability to apply for a new loan",
        label: "Inability to apply for a new loan",
      },
      {
        value: "Moratorium approved by the Bank",
        label: "Moratorium approved by the Bank",
      },
      {
        value: "DMP Enrollment",
        label: "DMP Enrollment",
      },
      {
        value: "Advisory",
        label: "Advisory",
      },
      {
        value: "Post DMP",
        label: "Post DMP",
      },
    ];

    const dmp_reason = [
      {
        value: "High Loan Commitment",
        label: "High Loan Commitment",
      },
      {
        value: "Holistic Loan Restructuring",
        label: "Holistic Loan Restructuring",
      },
      {
        value: "Non-Performing Loan",
        label: "Non-Performing Loan",
      },
      {
        value: "The Financial situation has improved",
        label: "The Financial situation has improved",
      },
      {
        value: "FIs Special Repayment Package",
        label: "FIs Special Repayment Package",
      },
      {
        value: "Remove CCRIS tagging",
        label: "Remove CCRIS tagging",
      },
      {
        value: "Apply for a new loan application",
        label: "Apply for a new loan application",
      },
      {
        value: "Reschedulement (Include new facility)",
        label: "Reschedulement (Include new facility)",
      },
      {
        value: "Reschedulement (Increase DMP Instalment)",
        label: "Reschedulement (Increase DMP Instalment)",
      },
      {
        value: "Reschedulement (Decrease DMP Instalment)",
        label: "Reschedulement (Decrease DMP Instalment)",
      },
      {
        value: "Amend Loan Details",
        label: "Amend Loan Details",
      },
      {
        value: "Amended required to comply parameter",
        label: "Amended required to comply parameter",
      },
      {
        value: "Change Matrix",
        label: "Change Matrix",
      },
      {
        value: "Change of Creditor",
        label: "Change of Creditor",
      },
      {
        value: "Change of Interest Rate",
        label: "Change of Interest Rate",
      },
      {
        value: "Change of Outstanding Balance",
        label: "Change of Outstanding Balance",
      },
      {
        value: "Medical Reason",
        label: "Medical Reason",
      },
      {
        value: "Reduction in Income",
        label: "Reduction in Income",
      },
      {
        value: "Increase in Income",
        label: "Increase in Income",
      },
    ];

    const reduceIncreaseInstallment = {
      estimated_installment: "",
    };

    const addOnnFacilities = {
      m1m4: {
        fis_facilities: "",
        step_up: "",
        estimated_installment: 0,
      },
      m2: {
        fis_facilities: "",
        reason: "",
        estimated_installment: 0,
        step_up: "",
      },
      m3: {
        fis: "",
        estimated_installment: 0,
      },
      m5: {
        fis: "",
        step_up: "",
        estimated_installment: 0,
      },
      m6: {
        fis: "",
        step_up: "",
        estimated_installment: 0,
      },
    };
    const banks = {};
    const facilityTypes = {};
    const vc = {
      link: "",
      email: "",
      advisor_note: "",
    };
    const matrixes = [
      {
        value: "M1",
        label: "M1",
      },
      {
        value: "M2",
        label: "M2",
      },
      {
        value: "M3",
        label: "M3",
      },
      {
        value: "M4",
        label: "M4",
      },
      {
        value: "M5",
        label: "M5",
      },
      // {
      //   value: "M6",
      //   label: "M6",
      // },
    ];

    const dmp = {
      estimated_installment: 0,
      no_of_facilities: "",
      total_estimated_dmp_installment: 0,
      m1m4: {
        step_up: "",
        estimated_installment: 0,
      },
      m2: {
        fis_facilities: "",
        reason: "",
        estimated_installment: 0,
      },
      m3: {
        fis: "",
        estimated_installment: 0,
      },
      m5: {
        fis: "",
        step_up: "",
        estimated_installment: 0,
      },
      m6: {
        fis: "",
        step_up: "",
        estimated_installment: 0,
      },
    };

    const typeOfBus = [
      { value: "Sole Proprietor", label: "Sole Proprietor" },
      { value: "Partnership", label: "Partnership" },
      { value: "Sdn Bhd", label: "Sdn Bhd" },
    ];

    const typeOfLenders = [
      { value: "Single", label: "Single" },
      { value: "Multiple", label: "Multiple" },
    ];

    const otherAdvisories = [
      { value: "Appeal to banks", label: "Appeal to banks" },
      {
        value: "Wound up / Bankrupt and to be refer to insolvency",
        label: "Wound up / Bankrupt and to be refer to insolvency",
      },
      {
        value: "Issues with non PFIs including suppliers/creditors",
        label: "Issues with non PFIs including suppliers/creditors",
      },
      {
        value: "Request for new individual loans/RA for individual loan",
        label: "Request for new individual loans/RA for individual loan",
      },
      {
        value: "Settle company loans as personal guarantor",
        label: "Settle company loans as personal guarantor",
      },
      { value: "Loan shark", label: "Loan shark" },
      { value: "Get government grants", label: "Get government grants" },
    ];

    const typeOfFinancialSkillsHh = [
      { value: "Reduce Loan Commitment", label: "Reduce Loan Commitment" },
      { value: "Cash Flow Management", label: "Cash Flow Management" },
      { value: "Basic Borrowings", label: "Basic Borrowings" },
      { value: "Budgeting", label: "Budgeting" },
      { value: "Savings", label: "Savings" },
      { value: "New Loan Application", label: "New Loan Application" },
      {
        value:
          "Recovery Action, Legal Action, Bankruptcy and Property Foreclosure",
        label:
          "Recovery Action, Legal Action, Bankruptcy and Property Foreclosure",
      },
      { value: "Mule Account", label: "Mule Account" },
      { value: "Emergency Fund", label: "Emergency Fund" },
      {
        value: "Banking Products and Services Conventional & Islamic",
        label: "Banking Products and Services Conventional & Islamic",
      },
      {
        value: "Banking Principles Conventional and Islamic",
        label: "Banking Principles Conventional and Islamic",
      },
      {
        value: "Basic Principles of Insurance & Takaful",
        label: "Basic Principles of Insurance & Takaful",
      },
    ];

    const typeOfFinancialSkillsBus = [
      {
        value: "Financial Landscape (Bijak Wang Bijak Niaga, BWBN)",
        label: "Financial Landscape (Bijak Wang Bijak Niaga, BWBN)",
      },
      { value: "E-Payment", label: "E-Payment" },
      {
        value: "Ethics & Values in Business",
        label: "Ethics & Values in Business",
      },
      { value: "Financial Scam", label: "Financial Scam" },
      {
        value: "Cash flow management  and budgeting",
        label: "Cash flow management  and budgeting",
      },
      {
        value: "New business loan request – referral to imSME",
        label: "New business loan request – referral to imSME",
      },
      {
        value: "Referral to Mybijakniaga platform",
        label: "Referral to Mybijakniaga platform",
      },
      { value: "Basic borrowings", label: "Basic borrowings" },
      {
        value:
          "Recovery action legal action, bankruptcy and property foreclosure",
        label:
          "Recovery action legal action, bankruptcy and property foreclosure",
      },
      {
        value: "Banking principles conventional and islamic",
        label: "Banking principles conventional and islamic",
      },
    ];

    return {
      //New
      actionRemarksCon,
      actionRemarksUnCon,
      addOnnFacilities,
      advisories,
      appointment,
      appointmentDetailsLogId: null,
      appointmentReady: false,
      appointmentSession,
      appointmentSessionExists: false,
      appointment_id,
      availableServices: [],
      banks,
      callResult,
      credit_rating_providers,
      cstmr_id,
      dmp,
      dmp_reason,
      facilityTypes,
      hh,
      isAdmin,
      isBranchHead,
      isFA,
      isNewAppointment: false,
      isVCLinkReady,
      isViewer,
      matrixes,
      // method_id,
      org_id: null,
      otherAdvisories,
      phone,
      post_dmp_enquiries,
      preAdvisory: "",
      reduceIncreaseInstallment,
      remarks,
      roles,
      route,
      router,
      services,
      sessionDateTime: moment().format("YYYY-MM-DD hh:mm:ss"),
      sme,
      store,
      typeOfBus,
      typeOfFinancialSkillsBus,
      typeOfFinancialSkillsHh,
      typeOfLenders,
      typeOfSourceOptions,
      type_of_account_enquiries,
      updateAdvisoryFormSchema,
      updatePhoneFormSchema,
      updateMethod: "",
      user: {} as any,
      vc,
    };
  },
  methods: {
    init() {
      this.getAuthUser();
      this.store.dispatch(Actions.GET_CREDITORS).then(() => {
        this.banks = this.store.getters.getCreditorsData;
      });
      this.store.dispatch(Actions.GET_FACILITIES).then(() => {
        this.facilityTypes = this.store.getters.getFacilitiesData;
      });
    },
    getAuthUser() {
      this.store.dispatch(Actions.GET_AUTH_USER).then(() => {
        this.user = this.store.getters.getProfile;
        this.org_id = this.user.organisation_id;
        this.getAppointmentData();
      });
    },
    getAppointmentData() {
      this.store.dispatch(Actions.GET_APPOINTMENT, this.appointment_id).then(
        (res) => {
          this.appointment = this.store.getters.getAppointmentData;
          this.phone.method_id = this.appointment.method_id;
          this.vc.email = this.appointment.user.email;

          const log = this.appointment.slot_logs.find(
            (item) => item.type === "Updated Appointment Details"
          );

          const vc_log = this.appointment.slot_logs.find(
            (item) => item.type === "Sent VC Link"
          );

          if (vc_log) {
            this.vc.link = vc_log.raw.link;
            this.vc.advisor_note = vc_log.raw.advisor_note;
            this.isVCLinkReady = true;
          }
          if (log) {
            this.appointmentSession =
              log.raw.appointment_session ?? this.appointmentSession;
            this.addOnnFacilities =
              log.raw.add_onn_facilities ?? this.addOnnFacilities;
            this.reduceIncreaseInstallment =
              log.raw.reduce_increase_installment ??
              this.reduceIncreaseInstallment;
            this.phone = log.raw.phone ?? this.phone;
            this.appointmentDetailsLogId = log.id;
            this.appointmentSessionExists = true;
            this.dmp = log.raw.dmp ?? this.dmp;
            this.sme = log.raw.sme ?? this.sme;
            this.hh = log.raw.hh ?? this.hh;

            if (this.appointmentSession) {
              this.onChangeAdvisory("init");
            }
          }

          if (
            this.appointment.fa_reference == "Outbound Call" &&
            this.appointment.source_type_id == null
          ) {
            this.typeOfSourceOptions = this.typeOfSourceOptions.filter(
              (item) => item.value !== "9"
            );
          }

          this.appointmentSession.source_type_id = this.appointment.source_type_id;

          setTimeout(() => {
            this.appointmentReady = true;
          }, 1000);

          // this.appointmentReady = true;
        },
        (err) => {
          // console.log(err);
        }
      );
    },
    today() {
      return moment().format("DD MMMM YYYY, dddd");
    },
    getHumanDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    changeMethod() {
      Swal.fire({
        title: "Please choose method.",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Phone Call",
        denyButtonText: "Walk In",
        confirmButtonColor: "#009ef7",
        denyButtonColor: "#009ef7",
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateMethod = "Phone Call";
          this.confirmChangeMethod();
        } else if (result.isDenied) {
          this.updateMethod = "Walk In";
          this.confirmChangeMethod();
        }
      });
    },
    confirmChangeMethod() {
      if (this.updateMethod == "Phone Call") {
        Swal.fire({
          title: "Are you sure want to change method to Phone Call?",
          text: "This cannot be undone. ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Start Phone Call Session",
        }).then((result) => {
          if (result.isConfirmed) {
            this.startSession();
          }
        });
      }

      if (this.updateMethod == "Video Call") {
        Swal.fire({
          title: "Are you sure want to change method to Video Call?",
          text: "This cannot be undone. ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Start Video Call Session",
        }).then((result) => {
          if (result.isConfirmed) {
            this.startSession();
          }
        });
      }

      if (this.updateMethod == "Walk In") {
        Swal.fire({
          title: "Are you sure want to change method to Walk In?",
          text: "This cannot be undone. ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Start Walk In Session",
        }).then((result) => {
          if (result.isConfirmed) {
            this.startSession();
          }
        });
      }
    },
    onStartSession() {
      if (this.appointment["method_id"] == 1) {
        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to continue with method Phone Call?",
          icon: "warning",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes, Start Phone Call Session",
          denyButtonText: "No, Change to Walk In",
        }).then((result) => {
          if (result.isConfirmed) {
            this.startSession();
          } else if (result.isDenied) {
            this.updateMethod = "Walk In";
            this.confirmChangeMethod();
          }
        });
      }

      if (this.appointment["method_id"] == 2 && this.isVCLinkReady) {
        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to continue with method Video Conference?",
          icon: "warning",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes, Start Video Conference Session",
          denyButtonText: "No, Change Method",
        }).then((result) => {
          if (result.isConfirmed) {
            this.startSession();
          } else if (result.isDenied) {
            this.updateMethod = "Walk In";
            this.changeMethod();
          }
        });
      }

      if (this.appointment["method_id"] == 2 && !this.isVCLinkReady) {
        Swal.fire({
          title: "Are you sure?",
          text:
            "You cannot start Video Conference Session because the link is not ready. Do you want to change Method?",
          icon: "warning",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Change Method to Walk In",
          denyButtonText: "Change Method to Phone Call",
        }).then((result) => {
          if (result.isConfirmed) {
            this.updateMethod = "Walk In";
            this.confirmChangeMethod();
          } else if (result.isDenied) {
            this.updateMethod = "Phone Call";
            this.confirmChangeMethod();
          }
        });
      }

      if (this.appointment["method_id"] == 3) {
        Swal.fire({
          title: "Are you sure?",
          text:
            "Do you want to continue with method " +
            this.appointment["method"].name_en +
            "?",
          icon: "warning",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes, Start Walk In Session",
          denyButtonText: "No, Change to Phone Call",
        }).then((result) => {
          if (result.isConfirmed) {
            this.startSession();
          } else if (result.isDenied) {
            this.updateMethod = "Phone Call";
            this.confirmChangeMethod();
          }
        });
      }
    },
    startSession() {
      //using the JSON methods for deep cloning
      let slot = JSON.parse(JSON.stringify(this.appointment));
      // slot.raw = {};
      slot.status = "In Progress";
      slot.fa_id = this.user.id;

      if (this.updateMethod == "Walk In") {
        slot.method_id = 3;
      } else if (this.updateMethod == "Video Call") {
        slot.method_id = 2;
      } else if (this.updateMethod == "Phone Call") {
        slot.method_id = 1;
      }

      this.store
        .dispatch(Actions.UPDATE_SLOT, slot)
        .then(() => {
          let slot_log = {
            slot_id: slot.id,
            type: "Updated Appointment Status",
            raw: {
              status: slot.status,
            },
            user_id: slot.fa_id,
          };

          this.updateLog(slot_log);

          if (this.appointment.method_id !== slot.method_id) {
            let method_log = {
              slot_id: slot.id,
              type: "Updated Appointment Method",
              raw: {
                original_method: this.appointment.method.name_en,
                updated_method: this.updateMethod,
              },
              user_id: slot.fa_id,
            };

            this.updateLog(method_log);
          }

          Swal.fire({
            title: "Congratulation!",
            text: "Appointment session has started!",
            icon: "success",
          }).then(() => {
            this.getAppointmentData();
          });
        })
        .catch((err) => {
          this.showSaveSessionError();
        });
    },
    updateLog(log) {
      this.store.dispatch(Actions.STORE_SLOT_LOG, log).then(() => {
        // console.log("log updated");
      });
    },
    onChangeAdvisory(from) {
      this.preAdvisory = this.appointmentSession.advisory;
      this.availableServices = [];
      let saved_service = "Please Select";
      if (from == "init") {
        saved_service = this.appointmentSession.latest_service;
        // console.log("saved_service", saved_service);
      } else {
        this.appointmentSession.agree_to_join_sdrs = "No";
        this.appointmentSession.agree_to_join_dmp = "No";
      }
      this.appointmentSession.latest_service = "";
      if (this.appointmentSession.advisory == "Advisory on DMP") {
        saved_service = "6";
        this.availableServices = this.services.DMP;
      } else if (this.appointmentSession.advisory == "Advisory for SME") {
        this.availableServices = this.services.SME;
      } else if (this.appointmentSession.advisory == "PFM") {
        this.availableServices = this.services.PFM;
      } else if (this.appointmentSession.advisory == "GE") {
        this.availableServices = this.services.GE;
      } else {
        this.availableServices = [];
      }
      // console.log(this.appointmentSession.latest_service)
      this.appointmentSession.latest_service = saved_service;
      // console.log("latest_service", this.appointmentSession.latest_service);
      // this.availableServices = [];
    },
    onChangeService() {
      this.sme.latest_service = this.appointmentSession.latest_service;
      this.hh.latest_service = this.appointmentSession.latest_service;
    },
    getAdvisory(service_id) {
      for (const key in this.services) {
        // console.log(this.services[key],service_id)
        if (Object.prototype.hasOwnProperty.call(this.services, key)) {
          const found = this.services[key].some(
            (service) => service.value === service_id
          );
          if (found) {
            return key;
          }
        }
      }
      return null; // or undefined, or any indicator that the value wasn't found
    },
    onSaveAdvisory() {
      const advisoryForm = this.$refs.advisoryForm as any;
      const phoneForm = this.$refs.phoneForm as any;

      // console.log(this.appointmentSession);

      if (
        this.appointment.method_id == 1 &&
        this.appointment.source_type_id !== 9
      ) {
        phoneForm.validate().then(({ valid }) => {
          if (valid) {
            // console.log("Phone Success");
            if (this.phone.result !== "Uncontactable") {
              advisoryForm.validate().then(({ valid }) => {
                if (valid) {
                  // console.log("Advisory success");
                  this.saveAdvisory();
                } else {
                  // console.log("Advisory error");
                }
              });
            } else {
              this.saveAdvisory();
            }
          } else {
            // console.log("Phone error");
          }
        });
      } else {
        advisoryForm.validate().then(({ valid }) => {
          if (valid) {
            // console.log("Advisory success");
            this.saveAdvisory();
          } else {
            // console.log("Advisory error");
          }
        });
      }
    },
    saveAdvisory() {
      // return;
      if (this.appointmentSession.agree_to_join_dmp == "Yes") {
        this.appointmentSession.agree_to_join_sdrs = "No";
      }
      if (this.appointmentSession.agree_to_join_sdrs == "Yes") {
        this.appointmentSession.agree_to_join_dmp = "No";
      }
      let log = {
        id: this.appointmentDetailsLogId,
        slot_id: this.appointment.id,
        type: "Updated Appointment Details",
        raw: {
          appointment_session: this.appointmentSession,
          add_onn_facilities: this.addOnnFacilities,
          reduce_increase_installment: this.reduceIncreaseInstallment,
          phone: this.phone,
          dmp: this.dmp,
          sme: this.sme,
          hh: this.hh,
        },
        user_id: this.appointment.fa_id,
      };
      let slot = JSON.parse(JSON.stringify(this.appointment));
      slot.latest_service_id = this.appointmentSession.latest_service;
      if (this.appointment.source_type_id == null) {
        slot.source_type_id = this.appointmentSession.source_type_id;
      }
      if (!slot.raw) {
        slot.raw = {};
      }
      this.store
        .dispatch(Actions.UPDATE_SLOT, slot)
        .then(() => {
          this.saveSlotLog(log);
        })
        .catch((err) => {
          this.showSaveSessionError();
        });
    },
    getSocialSynergyValue(value) {
      this.appointmentSession.referal_program.social_synergy = value;
    },
    saveSlotLog(log) {
      if (log.id) {
        this.store
          .dispatch(Actions.UPDATE_SLOT_LOG, log)
          .then(() => {
            if (log.type == "Updated Appointment Status") {
              this.showSaveSessionSuccess(log.raw.status);
            } else {
              this.showSaveSessionSuccess("updated");
            }
          })
          .catch((err) => {
            this.showSaveSessionError();
          });
      } else {
        this.store
          .dispatch(Actions.STORE_SLOT_LOG, log)
          .then(() => {
            if (log.type == "Updated Appointment Status") {
              this.showSaveSessionSuccess(log.raw.status);
            } else {
              this.showSaveSessionSuccess("saved");
            }
          })
          .catch((err) => {
            this.showSaveSessionError();
          });
      }
    },
    showSaveSessionSuccess(type) {
      let text = "";
      if (type == "Case Resolved" || type == "Case Closed") {
        text = "Advisory details has been saved. " + type + "!";
      } else {
        text = "Advisory details has been " + type + "!";
      }

      Swal.fire({
        title: "Congratulation!",
        text: text,
        icon: "success",
      }).then(() => {
        this.getAppointmentData();
      });
    },
    showSaveSessionError() {
      Swal.fire({
        icon: "error",
        title: "Session Error",
        text: "We encountered an issue. Please try again later.",
        footer: "If the problem persists, please contact support.",
      });
    },
    onEmailVCLink(index) {
      if (this.vc.link == "") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please enter Video Conference Link!",
        });
        return;
      }
      let slot_log = {
        slot_id: this.appointment.id,
        type: "Sent VC Link",
        raw: {
          link: this.vc.link,
          email: this.vc.email,
          advisor_note: this.vc.advisor_note,
        },
        user_id: this.user.id,
      };
      this.store.dispatch(Actions.STORE_SLOT_LOG, slot_log).then(() => {
        Swal.fire({
          title: "Congratulation!",
          text: "VC Link session has been sent!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        })
          .then(() => {
            window.location.reload();
          })
          .catch((err) => {
            this.showSaveSessionError();
          });
      });
    },
    onCompleteCase(type) {
      let slot = JSON.parse(JSON.stringify(this.appointment));
      slot.status = type;

      let log = {
        slot_id: slot.id,
        type: "Updated Appointment Status",
        raw: {
          status: slot.status,
        },
        user_id: slot.fa_id,
      };

      if (type == "Case Resolved") {
        if (
          this.appointment.slot_logs.some(
            (log) => log.type === "Updated Appointment Details"
          )
        ) {
          this.showWarningCompleteCase(type, slot, log);
        } else {
          Swal.fire({
            title: "Please make sure you update the Advisory details",
            icon: "warning",
            showCancelButton: false,
            confirmButtonText: "OK",
          });
        }
      } else {
        this.showWarningCompleteCase(type, slot, log);
      }
    },
    showWarningCompleteCase(type, slot, log) {
      Swal.fire({
        title: "Are you sure want proceed with " + type + "?",
        text: "This cannot be undone. ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Please proceed",
      }).then((result) => {
        if (result.isConfirmed) {
          this.store
            .dispatch(Actions.UPDATE_SLOT, slot)
            .then(() => {
              this.saveSlotLog(log);

              if (this.appointmentSession.agree_to_join_dmp == "Yes") {
                this.onJoinDMP(log);
              }

              if (this.appointmentSession.agree_to_join_sdrs == "Yes") {
                this.onJoinSDRS(log);
              }

              // if (this.appointmentSession.agree_to_join_sdrs == "Yes") {
              //   this.onJoinDMP(log);
              // }
            })
            .catch((err) => {
              this.showSaveSessionError();
            });
        }
      });
    },
    onJoinDMP(updateLog) {
      let log = {
        slot_id: updateLog.slot_id,
        type: "Customer Agree to Join DMP",
        raw: this.dmp,
        user_id: updateLog.fa_id,
      };

      this.store
        .dispatch(Actions.STORE_SLOT_LOG, log)
        .then(() => {
          // console.log("log updated");
        })
        .catch((err) => {
          this.showSaveSessionError();
        });
    },
    onJoinSDRS(updateLog) {
      let log = {
        slot_id: updateLog.slot_id,
        type: "Customer Agree to Join SDRS",
        raw: this.sme,
        user_id: updateLog.fa_id,
      };

      this.store
        .dispatch(Actions.STORE_SLOT_LOG, log)
        .then(() => {
          // console.log("log updated");
        })
        .catch((err) => {
          this.showSaveSessionError();
        });
    },
    onStartNewSession() {
      Swal.fire({
        text: "Creating New Session",
        icon: "info",
        buttonsStyling: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });
      //Set Appointment
      const appointment = {
        id: "",
        slot_id: "",
        fa_id: this.user.id,
        user_id: this.cstmr_id,
        method_id: 3,
        service_id: 27,
        organisation_id: this.org_id,
        appointment_date: Moment().format("YYYY-MM-DD"),
        slot_type: "appointment",
        start_time: Moment().format("HH:mm:ss"),
        end_time: Moment().add(30, "minutes").format("HH:mm:ss"),
        status: "New",
        fa_reference: "New Adhoc Session",
        source_type_id: 3,
      };

      this.store
        .dispatch(Actions.PRE_STORE_APPOINTMENT, appointment)
        .then(() => {
          let data = this.store.getters.getAppointmentData.data;
          appointment.id = data.id;
          appointment.slot_id = data.id;
          this.store
            .dispatch(Actions.STORE_RAW, {
              type: "appointment",
              user_id: this.cstmr_id,
              raw: appointment,
            })
            .then(() => {
              //Start the session
              let slot = JSON.parse(JSON.stringify(appointment));
              this.UpdateStatusAppointment(slot);
            });
        });
    },
    UpdateStatusAppointment(slot) {
      // slot.raw = {};
      slot.status = "In Progress";

      this.store
        .dispatch(Actions.UPDATE_SLOT, slot)
        .then(() => {
          let slot_log = {
            slot_id: slot.id,
            type: "Updated Appointment Status",
            raw: {
              status: slot.status,
            },
          };

          this.updateLog(slot_log);

          Swal.fire({
            title: "Congratulation!",
            text: "Appointment session has started!",
            icon: "success",
          }).then(() => {
            this.$router
              .push({
                path:
                  "/customers/details/" +
                  this.cstmr_id +
                  "/appointment/" +
                  slot.id,
              })
              .then(() => {
                // After navigation is confirmed, do something here
                window.location.reload();
              });
          });
        })
        .catch((err) => {
          this.showSaveSessionError();
        });
    },
  },
  async mounted() {
    this.init();
  },
});
